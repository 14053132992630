import GuidUtils from '@/shared/utils/guid'

export default class GymRatingCleanModel {
  constructor({
    id,
    clean,
    weight,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid
    this.clean = clean
    this.weight = weight
  }
}