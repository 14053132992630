import GuidUtils from '@/shared/utils/guid'

export default class GymRatingBsquatModel {
  constructor({
    id,
    jump,
    weight,
    height,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid
    this.jump = jump
    this.weight = weight
    this.height = height
  }
}