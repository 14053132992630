import GraphicBrenchPressMasculineAndJuvenil from '@/views/gym-rating/components/brenchPress/graphicBrenchPressMasculine/graphicBrenchPressMasculine.vue'
import GraphicBrenchPressFeminine from '@/views/gym-rating/components/brenchPress/graphicBrenchPressFeminine/graphicBrenchPressFeminine.vue'
import GymBrenchPress from '@/views/gym-rating/components/brenchPress/gymBrenchPress/gymBrenchPress.vue'
import GraphicBsquatMasculineAndJuvenil from '@/views/gym-rating/components/bsquat/graphicBsquatMasculine/graphicBsquatMasculine.vue'
import GraphicBsquatFeminine from '@/views/gym-rating/components/bsquat/graphicBsquatFeminine/graphicBsquatFeminine.vue'
import GraphicDeadLiftFeminine from '@/views/gym-rating/components/deadLift/graphicDeadLiftFeminine/graphicDeadLiftFeminine.vue'
import GraphicCleanFeminine from '@/views/gym-rating/components/clean/graphicCleanFeminine/graphicCleanFeminine.vue'
import GraphicDeadLiftMasculineAndJuvenil from '@/views/gym-rating/components/deadLift/graphicDeadLiftMasculine/graphicDeadLiftMasculine.vue'
import GraphicCleanMasculineAndJuvenil from '@/views/gym-rating/components/clean/graphicCleanMasculine/graphicCleanMasculine.vue'
import GraphicJump from '@/views/gym-rating/components/jump/graphicJump/graphicJump.vue'
import GymDeadLift from '@/views/gym-rating/components/deadLift/gymDeadLift/gymDeadLift.vue'
import GymBsquat from '@/views/gym-rating/components/bsquat/gymBsquat/gymBsquat.vue'
import GymJump from '@/views/gym-rating/components/jump/gymJump/gymJump.vue'
import GymRatingRepository from '@/shared/http/repositories/socialProject/gymRating'
import TeacherRepository from '@/shared/http/repositories/socialProject/teacher'
import GymClean from '@/views/gym-rating/components/clean/gymClean/gymClean.vue'
import ClassRepository from '@/shared/http/repositories/socialProject/class'
import BreadCrumb from '@/components/layout/BreadCrumb/breadCrumb.vue'
import Pagination from '@/components/layout/Pagination/pagination.vue'
import GymRatingCriteria from '@/shared/models/criteria/gymRating'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import Roles from '@/shared/mixins/hasRoles'
import fab from 'vue-fab'

export default {
  components: {
    [BreadCrumb.name]: BreadCrumb,
    [Pagination.name]: Pagination,
    [GymBrenchPress.name]: GymBrenchPress,
    GraphicBrenchPressMasculineAndJuvenil,
    GraphicBrenchPressFeminine,
    [GymBsquat.name]: GymBsquat,
    GraphicBsquatMasculineAndJuvenil,
    GraphicBsquatFeminine,
    [GymDeadLift.name]: GymDeadLift,
    GraphicDeadLiftMasculineAndJuvenil,
    GraphicDeadLiftFeminine,
    [GymClean.name]: GymClean,
    GraphicCleanMasculineAndJuvenil,
    GraphicCleanFeminine,
    [GymJump.name]: GymJump,
    GraphicJump,
    fab
  },

  mixins: [Roles],

  data: () => ({
    criteria: new GymRatingCriteria(),
    emptyText: 'Não há turmas cadastradas',
    showGraphicBrenchPressFeminine: false,
    showGraphicDeadLiftFeminine: false,
    showGraphicBsquatFeminine: false,
    showGraphicCleanFeminine: false,
    showModalDeleteGymRating: false,
    onShowDrawerBrenchPress: false,
    onShowDrawerDeadLift: false,
    onShowDrawerBsquat: false,
    onShowDrawerClean: false,
    onShowDrawerJump: false,
    itemBrenchPress: {},
    itemDeadLift: {},
    itemBsquat: {},
    itemClean: {},
    itemJump: {},
    selected: {},
    gymRatings: [],
    optionsTeachers: [],
    optionsClass: [],
    optionsCategory: [
      {value: 'JUNIOR E SENIOR', text: 'JUNIOR E SENIOR'},
      {value: 'JUVENIL I', text: 'JUVENIL I'},
      {value: 'JUVENIL II', text: 'JUVENIL II'},
    ],
    fields: [
      {
        key: 'name',
        label: 'NOME DA TEMPORADA',
        class: 'left-header-border text-center',
        sortable: true,
      },
      {
        key: 'className',
        label: 'TURMA',
        class: 'middle-header text-center'
      },
      {
        key: 'juvenileBsquatLiterature',
        label: 'LITERATURA - BSQUAT',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (item.category === 'JUVENIL I' || item.category === 'JUVENIL II') return value = item.juvenileBsquatLiterature
          if (item.category === 'JUNIOR E SENIOR') return value = `M/${item. masculineBsquatLiterature} - F/${item. feminineBsquatLiterature}`
        }
      },
      {
        key: 'juvenileBrenchpressLiterature',
        label: 'LITERATURA - SUPINO',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (item.category === 'JUVENIL I' || item.category === 'JUVENIL II') return value = item.juvenileBrenchpressLiterature
          if (item.category === 'JUNIOR E SENIOR') return value = `M/${item. masculineBrenchpressLiterature} - F/${item. feminineBrenchpressLiterature}`
        }
      },
      {
        key: 'juvenileDeadliftLiterature',
        label: 'LITERATURA - TERRA',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (item.category === 'JUVENIL I' || item.category === 'JUVENIL II') return value = item.juvenileDeadliftLiterature
          if (item.category === 'JUNIOR E SENIOR') return value = `M/${item. masculineDeadliftLiterature} - F/${item. feminineDeadliftLiterature}`
        }
      },
      {
        key: 'juvenileCleanLiterature',
        label: 'LITERATURA - CLEAN',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if (item.category === 'JUVENIL I' || item.category === 'JUVENIL II') return value = item.juvenileCleanLiterature
          if (item.category === 'JUNIOR E SENIOR') return value = `M/${item. masculineCleanLiterature} - F/${item. feminineCleanLiterature}`
        }
      },
      {
        key: 'jumpLiterature',
        label: 'LITERATURA - SALTO',
        class: 'middle-header text-center',
        formatter: (value) => {
          if (value == 0) return value = '-'
          return value
        }
      },
      {
        key: 'category',
        label: 'CATEGORIA',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'season',
        label: 'ANO DA TEMPORADA',
        class: 'middle-header text-center',
        sortable: true,
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 15,
      index: 1
    }
  }),

  computed: {
    breadCrumb() {
      return {
        breadcrumbItems: [
          {
            text: 'Dashboard',
            href: this.$router.resolve({ name: 'Dashboard' }).href
          },
          {
            text: 'Academia',
            active: true
          }
        ]
      }
    }
  },

  created() {
    this.getAllTeacher()
    this.getAll()
  },

  methods: {
    getAll() {
      this.criteria.teacherId = null

      loading.push()
      GymRatingRepository.GetAll(this.criteria, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()
          this.gymRatings = res.data.data

          if(res.data.count === 0) {
            toast.warning('Esta consulta não tem registros')
            this.gymRatings = []
            loading.pop()
            return Promise.resolve()
          }

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar lista de temporadas da academia', 'ERRO')
        })
    },

    onDelete() {
      loading.push()
      GymRatingRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.getAll()
          this.showModalDeleteGymRating = false
          toast.success('Temporada da Academia excluída com sucesso!', 'EXCLUSÃO')
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir modalidade', 'ERRO')
        })
    },

    getAllTeacher() {
      loading.push()
      TeacherRepository.GetAll()
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsTeachers = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar professores', 'ERRO')
        })
    },

    getClass() {
      if (!this.criteria.teacherId) {
        this.optionsClass = []
        return
      }

      loading.push()
      ClassRepository.GetAll(this.criteria)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.optionsClass = res.data.data
          loading.pop()
          return Promise.resolve()
        })
        .catch((err) => {
          loading.pop()
          console.log(err)
          toast.error(err, 'ERRO')
        })
    },

    selectRow(item) {
      this.showModalDeleteGymRating = true
      this.selected = item
      this.id = item.id
    },

    reloadAllTables() {
      this.getAll(this.criteria, this.pagination)
    },

    showDrawerBsquat(item) {
      this.itemBsquat = item
      this.onShowDrawerBsquat = true
    },

    onCloseDrawerBsquat() {
      this.onShowDrawerBsquat = false;
    },

    onShowGraphicBsquatFeminine(bool) {
      this.showGraphicBsquatFeminine = bool
    },

    showDrawerBrenchPress(item) {
      this.itemBrenchPress = item
      this.onShowDrawerBrenchPress = true
    },

    onCloseDrawerBrenchPress() {
      this.onShowDrawerBrenchPress = false;
    },

    onShowGraphicBrenchPressFeminine(bool) {
      this.showGraphicBrenchPressFeminine = bool
    },

    showDrawerDeadLift(item) {
      this.itemDeadLift = item
      this.onShowDrawerDeadLift = true
    },

    onCloseDrawerDeadLift() {
      this.onShowDrawerDeadLift = false;
    },

    onShowGraphicDeadLiftFeminine(bool) {
      this.showGraphicDeadLiftFeminine = bool
    },

    showDrawerClean(item) {
      this.itemClean = item
      this.onShowDrawerClean = true
    },

    onCloseDrawerClean() {
      this.onShowDrawerClean = false;
    },

    onShowGraphicCleanFeminine(bool){
      this.showGraphicCleanFeminine = bool
    },

    showDrawerJump(item) {
      if (item.category == 'JUVENIL I' || item.category == 'JUVENIL II') {
        toast.info ('Categoria juvenil não possui modalidade SALTO', 'AVISO')
        return
      }
      this.itemJump = item
      this.onShowDrawerJump = true
    },

    onCloseDrawerJump() {
      this.onShowDrawerJump = false;
    },

    toBsquatExcel(classAverage) { this.$refs.GymBsquat.classAverage = classAverage },

    toBsquatFeminineExcel(avg) { this.$refs.GymBsquat.classAverageFeminine = avg },

    toBrenchPressExcel(classAverage) { this.$refs.GymBrenchPress.classAverage = classAverage },

    toBrenchFeminineExcel(avg) { this.$refs.GymBrenchPress.classAverageFeminine = avg },

    toDeadLiftExcel(classAverage) { this.$refs.GymDeadLift.classAverage = classAverage },

    toDeadLiftFeminineExcel(avg) { this.$refs.GymDeadLift.classAverageFeminine = avg },

    toCleanExcel(classAverage) { this.$refs.GymClean.classAverage = classAverage },

    toCleanFeminineExcel(avg) { this.$refs.GymClean.classAverageFeminine = avg },

    toJumpExcel(avg) { this.$refs.GymJump.classAverage = avg },

    reloadGraphicJump(id) { this.$refs.GraphicJump.loadChargeData(id) },

    reloadGraphicBsquat(id, category) {
      if (category == 'JUVENIL I' || category == 'JUVENIL II') {
        this.$refs.GraphicBsquatMasculineAndJuvenil.loadChargeData(id)
        return
      }
      this.$refs.GraphicBsquatMasculineAndJuvenil.loadChargeData(id)
      this.$refs.GraphicBsquatFeminine.loadChargeData(id)
    },

    reloadGraphicBrenchPress(id, category) {
      if (category == 'JUVENIL I' || category == 'JUVENIL II') {
        this.$refs.GraphicBrenchPressMasculineAndJuvenil.loadChargeData(id)
        return
      }
      this.$refs.GraphicBrenchPressMasculineAndJuvenil.loadChargeData(id)
      this.$refs.GraphicBrenchPressFeminine.loadChargeData(id)
    },

    reloadGraphicDeadLift(id, category) {
      if (category == 'JUVENIL I' || category == 'JUVENIL II') {
        this.$refs.GraphicDeadLiftMasculineAndJuvenil.loadChargeData(id)
        return
      }
      this.$refs.GraphicDeadLiftMasculineAndJuvenil.loadChargeData(id)
      this.$refs.GraphicDeadLiftFeminine.loadChargeData(id)
    },

    reloadGraphicClean(id, category) {
      if (category == 'JUVENIL I' || category == 'JUVENIL II') {
        this.$refs.GraphicCleanMasculineAndJuvenil.loadChargeData(id)
        return
      }
      this.$refs.GraphicCleanMasculineAndJuvenil.loadChargeData(id)
      this.$refs.GraphicCleanFeminine.loadChargeData(id)
    }
  }
}
