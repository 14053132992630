import MeasurementRepository from '@/shared/http/repositories/socialProject/student-measurement'
import GymRatingCleanRepository from '@/shared/http/repositories/socialProject/gymRating-clean'
import GymRatingRepository from '@/shared/http/repositories/socialProject/gymRating'
import MeasurementModel from '@/shared/models/measurementModel'
import GymRatingCleanModel from '@/shared/models/gymRatingClean'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import download from 'downloadjs'

export default {
  name: 'GymClean',

  props: {
    items: {
      type: Object,
      default: {}
    }
  },

  data: () => ({
    gymRatingClean: new GymRatingCleanModel(),
    measurement: new MeasurementModel(),
    emptyText: 'Não há nada para exibir',
    classAverageFeminine: 0,
    totalRegister: 0,
    classAverage: 0,
    enableExportButtonFeminine: false,
    showModalDeleteClean: false,
    showModalMeasurement: false,
    showInputClean: false,
    studentId: null,
    studentName: '',
    literature: null,
    selected: {},
    studentsClean: [],
    fieldsGymClean: [
      {
        key: 'studentName',
        label: 'NOME',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'weight',
        label: 'PESO CORPORAL (KG)',
        class: 'middle-header text-center'
      },
      {
        key: 'clean',
        label: 'CLEAN',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'percentWeight',
        label: '% AO PESO',
        class: 'middle-header text-center',
        formatter: 'calculatePercentWeight'
      },
      {
        key: 'situation',
        label: 'RELAÇÃO',
        class: 'middle-header text-center',
        formatter: 'checkSituation'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 15,
      index: 1
    }
  }),

  watch: {
    items: {
      handler: function (value) {
        if (value.id) {
          this.GetByGymRatingId(value.id)
        }
      },
      immediate: true
    }
  },

  methods: {
    GetByGymRatingId(id) {
      loading.push()
      GymRatingCleanRepository.GetGymRatingCleanByGymRatingId(id, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.studentsClean = res.data.data

          const haveStudentFeminine = this.studentsClean.find(x => x.studentGender == 'Feminino')
          if (haveStudentFeminine && this.items.category == 'JUNIOR E SENIOR') {
            this.enableExportButtonFeminine = true
            this.$emit('onShowGraphicCleanFeminine', true)
          }
          else{ 
            this.enableExportButtonFeminine = false 
            this.$emit('onShowGraphicCleanFeminine', false)
          }

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          if (res.data.count > 0) {
            this.totalRegister = res.data.count
          } else {
            this.totalRegister = 0
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tabela clean', 'ERRO')
        })
    },

    onSaveCreate(text) {
      if (text == 'yesValidation') {
        const clean = this.gymRatingClean.clean
        const weight = this.gymRatingClean.weight
  
        if (!clean || !weight) {
          toast.info('Preencha os campos', 'AVISO')
          return
        }
      }

      loading.push()
      GymRatingCleanRepository.Update(this.gymRatingClean)
        .then(result => {
          if (!IsSuccessCode(result)) return Promise.reject()
          this.showInputClean = false

          this.$emit('reloadGraphicClean', this.items.id, this.items.category)

          if (text == 'yesValidation') {
            this.GetByGymRatingId(this.items.id)
          }
          
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível salvar registro.', 'ERRO')
          loading.pop()
        })
    },

    GetById() {
      loading.push()
      GymRatingCleanRepository.GetById(this.gymRatingClean.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.gymRatingClean = new GymRatingCleanModel(res.data.data)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tabela clean', 'ERRO')
        })
    },

    createStudentMeasurement() {
      const height = this.measurement.height
      const weight = this.measurement.weight

      if (!height || !weight) {
        toast.info('Preencha os campos', 'AVISO')
        return
      }

      this.gymRatingClean.weight = weight

      loading.push()
      MeasurementRepository.Create(this.studentId, this.measurement)
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()
        
        this.gymRatingClean.clean = null
        this.onSaveCreate('noValidation')

        this.onSaveWeightCategories(this.items.id, this.studentId, weight, height)

        this.showModalMeasurement = false
        
        toast.success('Medida criada com sucesso!', 'CRIAÇÃO DE MEDIÇÃO')
        loading.pop()
        return Promise.resolve()
      })
      .catch(() => {
        loading.pop()
        toast.error('Erro ao criar medida', 'ERRO')
      })
    },

    onSaveWeightCategories(gymRatingId, studentId, weight, height) {
      loading.push()
      GymRatingRepository.SaveWeightOnTables(gymRatingId, studentId, weight, height)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.onClearModalMeasurement()
          this.onClearModalInputClean()

          this.GetByGymRatingId(this.items.id)
          this.$emit('reloadAllTables')

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar peso nas tabelas', 'ERRO')
        })
    },

    checkModal(item) {
      this.gymRatingClean.id = item.id
      this.studentName = item.studentName

      if (item.weight == null) {
        this.studentId = item.studentId
        this.measurement.height = null
        this.measurement.weight = null
        this.showModalMeasurement = true
        return
      }

      this.studentId = null
      this.GetById()
      this.showInputClean = true
    },

    calculatePercentWeight(value, key, item) {
      const weight = item.weight
      const clean = item.clean
      
      if (!clean || !weight){
        return value = '-'
      }

      const calc1 = item.clean / item.weight
      const calc2 = calc1 * 100
      const result = Number.parseFloat(calc2).toFixed(2)

      return value = `${result} %`
    },

    checkSituation(value, key, item) {
      const weight = item.weight
      const clean = item.clean
      
      if (!clean || !weight){
        return value = '-'
      }

      const category = this.items.category

      const calc1 = item.clean / item.weight
      const calc2 = calc1 * 100
      const result = Number.parseFloat(calc2).toFixed(2)

      if (category === 'JUVENIL I' || category === 'JUVENIL II') {
        const literature = this.items.juvenileCleanLiterature
        const literaturePercent = literature * 100

        if (result > literaturePercent) {
          const text = 'Acima do Esperado'
          return value = text
        }

        if (result == literaturePercent) {
          const text = 'Na Média'
          return value = text
        }

        if (result < literaturePercent) {
          const text = 'Abaixo do Esperado'
          return value = text
        }
      }

      // Junior e Senior
      if (item.studentGender == 'Feminino') {
        const literatureFeminine = this.items.feminineCleanLiterature
        const literaturePercent = literatureFeminine * 100

        if (result > literaturePercent) {
          const text = 'F/ - Acima do Esperado'
          return value = text
        }

        if (result == literaturePercent) {
          const text = 'F/ - Na Média'
          return value = text
        }

        if (result < literaturePercent) {
          const text = 'F/ - Abaixo do Esperado'
          return value = text
        }
      }

      if (item.studentGender == 'Masculino') {
        const literatureMasculine = this.items.masculineCleanLiterature
        const literaturePercent = literatureMasculine * 100
        
        if (result > literaturePercent) {
          const text = `${'M/ - Acima do Esperado'}`
          return value = text
        }

        if (result == literaturePercent) {
          const text = 'M/ - Na Média'
          return value = text
        }

        if (result < literaturePercent) {
          const text = 'M/ - Abaixo do Esperado'
          return value = text
        }
      }
    },

    downloadExcel() { // Juvenil, Junior e Senior - Masculino
      const category = this.items.category

      if (category == 'JUVENIL I' || category == 'JUVENIL II') this.literature = this.items.juvenileCleanLiterature

      if (category == 'JUNIOR E SENIOR') this.literature = this.items.masculineCleanLiterature

      loading.push()
      GymRatingCleanRepository.ExportExcelJuvAndSeniorMasculine(this.items.id, this.classAverage, this.literature, this.items.category, this.items.className)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    downloadExcelFeminine() { // Junior e Senior - Feminino
      const category = this.items.category

      if (category == 'JUVENIL I' || category == 'JUVENIL II') return

      this.literature = this.items.feminineCleanLiterature

      loading.push()
      GymRatingCleanRepository.ExportExcelSeniorFeminine(this.items.id, this.classAverageFeminine, this.literature, this.items.category, this.items.className)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    selectRow(item) {
      this.showModalDeleteClean = true
      this.selected = item
      this.id = item.id
    },

    onDelete() {
      loading.push()
      GymRatingCleanRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByGymRatingId(this.items.id)
          this.onCloseModalDeleteClean()
          toast.success('Média zerada com sucesso!', 'EXCLUSÃO DE MÉDIA')
          this.$emit('reloadGraphicClean', this.items.id, this.items.category)
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir um clean', 'ERRO')
        })
    },

    clearDrawer() {
      this.studentsClean = []
      this.totalRegister = 0
      this.gymRatingClean = new GymRatingCleanModel()
      this.selected = {}
    },

    onCloseModalDeleteClean() {
      this.selected = {}
      this.showModalDeleteClean = false
    },

    onClearModalMeasurement() {
      this.measurement = new MeasurementModel()
      this.showModalMeasurement = false
    },

    onClearModalInputClean() {
      this.gymRatingClean.weight = null
      this.gymRatingClean.clean = null
      this.showInputClean = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}