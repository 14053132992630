export default class GymRatingCriteria {
    constructor({
      classId,
      name,
      season,
      teacherId,
      category
    } = {}) {
      this.classId = classId
      this.name = name
      this.season = season
      this.teacherId = teacherId
      this.category = category
    }
  }