import GuidUtils from '@/shared/utils/guid'

export default class GymRatingBrenchPressModel {
  constructor({
    id,
    brenchPress,
    weight,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid
    this.brenchPress = brenchPress
    this.weight = weight
  }
}