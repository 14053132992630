import MeasurementRepository from '@/shared/http/repositories/socialProject/student-measurement'
import GymRatingJumpRepository from '@/shared/http/repositories/socialProject/gymRating-jump'
import GymRatingRepository from '@/shared/http/repositories/socialProject/gymRating'
import MeasurementModel from '@/shared/models/measurementModel'
import GymRatingJumpModel from '@/shared/models/gymRatingJump'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import download from 'downloadjs'

export default {
  name: 'GymJump',

  props: {
    items: {
      type: Object,
      default: {}
    }
  },

  data: () => ({
    gymRatingJump: new GymRatingJumpModel(),
    measurement: new MeasurementModel(),
    emptyText: 'Não há nada para exibir',
    classAverageFeminine: 0,
    totalRegister: 0,
    classAverage: 0,
    enableExportButtonFemale: false,
    showModalDeleteJump: false,
    showModalMeasurement: false,
    showInputJump: false,
    studentId: null,
    studentName: '',
    literature: null,
    selected: {},
    studentsJump: [],
    fieldsGymJump: [
      {
        key: 'studentName',
        label: 'NOME',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'weight',
        label: 'PESO CORPORAL (KG)',
        class: 'middle-header text-center'
      },
			{
        key: 'height',
        label: 'ALTURA',
        class: 'middle-header text-center'
      },
      {
        key: 'jump',
        label: 'SALTO (M)',
        class: 'middle-header text-center',
        sortable: true
      },
      {
        key: 'percentHeight',
        label: '% DA ALTURA',
        class: 'middle-header text-center',
        formatter: 'calculatePercentHeight'
      },
      {
        key: 'situation',
        label: 'RELAÇÃO',
        class: 'middle-header text-center',
        formatter: 'checkSituation'
      },
      {
        key: 'action',
        label: 'AÇÕES',
        class: 'right-header-border text-center'
      }
    ],
    pagination: {
      count: 1,
      limit: 15,
      index: 1
    }
  }),

  watch: {
    items: {
      handler: function (value) {
        if (value.id) {
          this.GetByGymRatingId(value.id)
        }
      },
      immediate: true
    }
  },

  methods: {
    GetByGymRatingId(id) {
      loading.push()
      GymRatingJumpRepository.GetGymRatingJumpByGymRatingId(id, this.pagination)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.studentsJump = res.data.data

          this.pagination = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }

          if (res.data.count > 0) {
            this.totalRegister = res.data.count
          } else {
            this.totalRegister = 0
          }

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tabela de salto', 'ERRO')
        })
    },

    onSaveCreate(text) {
      if (text == 'yesValidation') {
        const jump = this.gymRatingJump.jump
        const weight = this.gymRatingJump.weight
        const height = this.gymRatingJump.height
  
        if (!jump || !weight || !height) {
          toast.info('Preencha os campos', 'AVISO')
          return
        }
      }

      loading.push()
      GymRatingJumpRepository.Update(this.gymRatingJump)
        .then(result => {
          if (!IsSuccessCode(result)) return Promise.reject()
          this.showInputJump = false
          this.$emit('reloadGraphicJump', this.items.id)

          if (text == 'yesValidation') {
            this.GetByGymRatingId(this.items.id)
          }
          
          loading.pop()
        })
        .catch(() => {
          toast.error('Não foi possível salvar registro.', 'ERRO')
          loading.pop()
        })
    },

    GetById() {
      loading.push()
      GymRatingJumpRepository.GetById(this.gymRatingJump.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.gymRatingJump = new GymRatingJumpModel(res.data.data)

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar tabela jump', 'ERRO')
        })
    },

    createStudentMeasurement() {
      const height = this.measurement.height
      const weight = this.measurement.weight

      if (!height || !weight) {
        toast.info('Preencha os campos', 'AVISO')
        return
      }

      this.gymRatingJump.weight = weight
      this.gymRatingJump.height = height

      loading.push()
      MeasurementRepository.Create(this.studentId, this.measurement)
      .then(res => {
        if (!IsSuccessCode(res)) return Promise.reject()
        
        this.gymRatingJump.jump = null
        this.onSaveCreate('noValidation')

        this.onSaveWeightCategories(this.items.id, this.studentId, this.gymRatingJump.weight, this.gymRatingJump.height)

        this.showModalMeasurement = false
        
        toast.success('Medida criada com sucesso!', 'CRIAÇÃO DE MEDIÇÃO')
        loading.pop()
        return Promise.resolve()
      })
      .catch(() => {
        loading.pop()
        toast.error('Erro ao criar medida', 'ERRO')
      })
    },

    onSaveWeightCategories(gymRatingId, studentId, weight, height) {
      loading.push()
      GymRatingRepository.SaveWeightOnTables(gymRatingId, studentId, weight, height)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.onClearModalMeasurement()
          this.onClearModalInputJump()

          this.GetByGymRatingId(this.items.id)
          this.$emit('reloadAllTables')

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar peso nas tabelas', 'ERRO')
        })
    },

    checkModal(item) {
      this.gymRatingJump.id = item.id
      this.studentName = item.studentName

      if (item.weight == null) {
        this.studentId = item.studentId
        this.measurement.height = null
        this.measurement.weight = null
        this.showModalMeasurement = true
        return
      }

      this.studentId = null
      this.GetById()
      this.showInputJump = true
    },

    calculatePercentHeight(value, key, item) {
      const height = item.height
      const jump = item.jump
      
      if (!jump || !height){
        return value = '-'
      }

      const calc1 = jump / height
      const calc2 = calc1 * 100
      const result = Number.parseFloat(calc2).toFixed(2)

      return value = `${result} %`
    },

    checkSituation(value, key, item) {
      const height = item.height
      const jump = item.jump
      
      if (!jump || !height) {
        return value = '-'
      }

      const calc1 = jump / height
      const calc2 = calc1 * 100
      const result = Number.parseFloat(calc2).toFixed(2)

			const literature = this.items.jumpLiterature
			const literaturePercent = literature * 100

			if (result > literaturePercent) {
				const text = 'Acima do Esperado'
				return value = text
			}

			if (result == literaturePercent) {
				const text = 'Na Média'
				return value = text
			}

			if (result < literaturePercent) {
				const text = 'Abaixo do Esperado'
				return value = text
			}
    },

    downloadExcel() { // Junior e Senior
      this.literature = this.items.jumpLiterature

      loading.push()
      GymRatingJumpRepository.ExportExcelJrAndSenior(this.items.id, this.classAverage, this.literature, this.items.category, this.items.className)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if(res.data.data === null) {
            toast.warning('Esta consulta não tem registros e não irá gerar planilha')
            loading.pop()
            return Promise.resolve()
          }

          const file = res.data.data
          const base64 = `data:${file.contentType};base64,${file.base64}`
          download(base64, file.alias, file.contentType)
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao exportar excel', 'ERRO')
        })
    },

    selectRow(item) {
      this.showModalDeleteJump = true
      this.selected = item
      this.id = item.id
    },

    onDelete() {
      loading.push()
      GymRatingJumpRepository.Delete(this.id)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.GetByGymRatingId(this.items.id)
          this.onCloseModalDeleteJump()
          toast.success('Média zerada com sucesso!', 'EXCLUSÃO DE MÉDIA')
          this.$emit('reloadGraphicJump', this.items.id)
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao excluir um salto', 'ERRO')
        })
    },

    clearDrawer() {
      this.studentsJump = []
      this.totalRegister = 0
      this.gymRatingJump = new GymRatingJumpModel()
      this.selected = {}
    },

    onCloseModalDeleteJump() {
      this.selected = {}
      this.showModalDeleteJump = false
    },

    onClearModalMeasurement() {
      this.measurement = new MeasurementModel()
      this.showModalMeasurement = false
    },

    onClearModalInputJump() {
      this.gymRatingJump.weight = null
      this.gymRatingJump.height = null
      this.gymRatingJump.jump = null
      this.showInputJump = false
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
}