import GuidUtils from '@/shared/utils/guid'

export default class GymRatingBsquatModel {
  constructor({
    id,
    bsquat,
    weight,
  } = {}) {
    this.id = id || GuidUtils.emptyGuid
    this.bsquat = bsquat
    this.weight = weight
  }
}